import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import './layout.css'



const Alldefinedtags = () => {
    const data = useStaticQuery(graphql`
 query {
allWordpressTag(sort: {fields: name}) {
    edges {
      node {
        name
        slug
        id
      }
    }
  }
  }
  `)

    return (
        <ul className="alltags">
            <li>Tags: </li>
            {data.allWordpressTag.edges.map(({ node }) => (
                <li key={node.id}>

                    <Link
                        to={`/tag/${node.slug}`}
                        aria-label={`Continue reading ${node.name}`}
                    >

                            {node.name}

                    </Link>
                </li>
            ))}

        </ul>
    )
}



export default Alldefinedtags
