import React from "react"
import SEO from "../components/seo"
import Img from "gatsby-image";
import Layout from "../components/layout"
import Alldefinedtags from "../components/alldefinedtags";
import {Link, graphql} from "gatsby";




const IndexPage = (props) => (

    <Layout>
        <SEO
            title="Blog about moving from CMS core to headless javascript solutions"
            description="Read more about the things I tried, the resources I use and the problems I encounter moving to new solutions"
        />

        <h2
        >
            Welcome! I'm Marie-Anne Melis
        </h2>
            <p>For many years I ran my freelance business based on Joomla and Wordpress core CMS, sometimes making
                    custom solutions, making many
                    custom themes and templates and having a lot of fun.</p>
            <p>Do you feel the but coming? But, part of the work has disappeared, free websiteplatforms, pagebuilders
                    and more and it was time to
                    dive into new possibilities.</p>
            <p>Exciting new possibilities, they take a lot of time to explore and learn. On this website I write
                    about my journey, resources I
                    used and use and the problems I encounter.</p>

        <Img
            data-pin-nopin="true"
            alt="How React warnings can make you feel"
            fluid={props.data.desperate.childImageSharp.fluid}
        />
        <p><em>This is how react-hot-loader, react-helmet and gatsby images can make you feel.</em></p>
            <p>This website is also a work in progress in itself, so if you see warnings in the developertool, duh, life
                    long learning isn't it?</p>
            <p>I have divided my blogs into categories (and tags). A thing in itself I'll maybe mention in a separate
                    blog. Here they are:</p>
        <p>Category: <Link  className="inlineLink" to={`/category/learning/`}>Learning</Link></p>
        <Alldefinedtags/>

        <p>Feel free to contact me at <a href="mailto:question@emma-design.nl">question@emma-design.nl</a></p>



    </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    desperate: file(relativePath: { eq: "howdoifeelonerror.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`